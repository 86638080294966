<template>
  <el-row style="margin-bottom:20px;">
    <div class="main-width-80">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item v-for="(item, index) in pageContent.path" :key="index"
                            :to="{ path: `/page/details?pageId=${item.id}` }">{{ item.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
      <el-container>
        <el-main style="padding: 20px 20px 0 0" class="order-el-main">
          <p class="h1-title">Send a Parcel</p>
          <el-row>
            <p class="toggle">
              Guided Steps
              <span @click="toggle('off')" :class="{ 'toggle-active': ret == 'off' }">off</span
              >
              <span
                  @click="toggle('on')"
                  :class="{ 'toggle-active': ret == 'on' }"
              >on</span
              >
            </p>
          </el-row>
          <el-row class="order-flex-start" v-if="ret == 'on'">
            <el-col :span="6">
              <div class="order-step">
                <el-steps
                    direction="vertical"
                    :active="active"
                    :space="80"
                    process-status="finish"
                    finish-status="success"
                >
                  <el-step title="Where"></el-step>
                  <el-step title="What"></el-step>
                  <el-step title="Payment"></el-step>
                </el-steps>
              </div>
            </el-col>
            <el-col :span="16" v-if="active == 0">
              <p class="title">Hello. Where are you shipping from?</p>
              <el-form
                  style="text-align: left"
                  ref="formData"
                  :model="formData"
                  label-width="80px"
                  label-position="top"
                  :rules="rules"
              >
                <el-form-item prop="country" label="Country or Territory">
                  <el-select
                      style="width: 100%"
                      v-model="formData.country"
                      placeholder="Please select country or region"
                  >
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div>
                  <el-form-item
                      prop="fullCompanyName"
                      label="Full Name or Company Name"
                  >
                    <el-input v-model="formData.fullCompanyName"></el-input>
                  </el-form-item>
                  <el-form-item label="Contact Name">
                    <el-input v-model="formData.contactName"></el-input>
                  </el-form-item>
                </div>

                <el-form-item prop="address1" label="Address Line 1">
                  <el-input v-model="formData.address1"></el-input>
                </el-form-item>
                <el-form-item label="Address Line 2">
                  <el-input v-model="formData.address2"></el-input>
                </el-form-item>
                <el-form-item label="Address Line 3">
                  <el-input v-model="formData.address3"></el-input>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item prop="city" label="City">
                      <el-input v-model="formData.city"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item prop="postalCode" label="Postal Code">
                      <el-input v-model="formData.postalCode"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Other Address Information">
                      <el-input v-model="formData.information"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-checkbox v-model="formData.residentialAddress"
                >Is this a residential address?
                </el-checkbox
                >
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item label="Email">
                      <el-input v-model="formData.email"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Phone">
                      <el-input v-model="formData.phone"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="Extension">
                      <el-input v-model="formData.extension"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-checkbox v-model="formData.above"
                >Send status updates on this shipment using the email provided
                  above.
                </el-checkbox>
                <el-row :gutter="10">
                  <el-col :span="12">
                    <el-form-item label="Save As New Entry">
                      <el-switch v-model="formData.newEntry"></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Use this as my default return address">
                      <el-switch v-model="formData.defalutAddress"></el-switch>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item
                    label="Nickname"
                    v-if="formData.newEntry && !formData.defalutAddress"
                >
                  <el-input v-model="formData.nickname"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="continueNext(1)"
                  >continue
                  </el-button
                  >
                  <el-button type="text" @click="quit">Cancel Shipment</el-button>
                </el-form-item>
              </el-form>
            </el-col>

            <el-col :span="16" v-if="active == 1">
              <el-row>
                <div class="shipFormEdit">
                  <h1>Ship From/Return To</h1>
                  <span @click="preItem()">
                                <i class="el-icon-edit"></i>
                                Edit
                              </span>
                </div>
                <el-divider></el-divider>
                <div class="info">
                  <p>{{ formData.fullCompanyName }} {{ formData.phone }}</p>
                  <p>{{ formData.address1 }} {{ formData.city }} {{ formData.postalCode }}</p>
                  <span></span>
                </div>


              </el-row>
              <p class="title">Where is your shipment going?</p>
              <el-form
                  style="text-align: left"
                  ref="formData1"
                  :model="formData1"
                  label-width="80px"
                  label-position="top"
                  :rules="rules"
              >
                <el-form-item prop="country" label="Country or Territory">
                  <el-select
                      style="width: 100%"
                      v-model="formData.country"
                      placeholder="Please select country or region"
                  >
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div>
                  <el-form-item
                      prop="fullCompanyName"
                      label="Full Name or Company Name"
                  >
                    <el-input v-model="formData1.fullCompanyName"></el-input>
                  </el-form-item>
                  <el-form-item label="Contact Name">
                    <el-input v-model="formData1.contactName"></el-input>
                  </el-form-item>
                </div>

                <el-form-item prop="address1" label="Address Line 1">
                  <el-input v-model="formData1.address1"></el-input>
                </el-form-item>
                <el-form-item label="Address Line 2">
                  <el-input v-model="formData1.address2"></el-input>
                </el-form-item>
                <el-form-item label="Address Line 3">
                  <el-input v-model="formData1.address3"></el-input>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item prop="city" label="City">
                      <el-input v-model="formData1.city"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item prop="postalCode" label="Postal Code">
                      <el-input v-model="formData1.postalCode"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Other Address Information">
                      <el-input v-model="formData1.information"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-checkbox v-model="formData1.residentialAddress"
                >Is this a residential address?
                </el-checkbox
                >
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item label="Email">
                      <el-input v-model="formData1.email"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Phone">
                      <el-input v-model="formData1.phone"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="Extension">
                      <el-input v-model="formData1.extension"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <p>
                  UPS may use the email address and/or mobile number provided to
                  update your receiver about the status of their parcel.
                </p>

                <el-row :gutter="10">
                  <el-col :span="12">
                    <el-form-item label="Save As New Entry">
                      <el-switch v-model="formData1.newEntry"></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Nickname" v-if="formData1.newEntry">
                      <el-input v-model="formData1.nickname"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item>
                  <el-button @click="preItem()">Back</el-button>
                  <el-button type="primary" @click="continueNext1(2)"
                  >continue
                  </el-button
                  >
                  <el-button type="text" @click="quit()">Cancel Shipment</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="16" v-if="active == 2">
              <p class="title">How would you like to pay?</p>
              <p class="total-charges"><span>Total Charges:</span> <span>65.00 USD</span></p>
              <el-form
                  style="text-align: left"
                  ref="paymentFormData"
                  :model="paymentFormData"
                  label-width="80px"
                  label-position="top"
                  :rules="paymentRuls"
              >
                <el-form-item prop="cardType" label="Card Type ">
                  <el-select
                      style="width: 100%"
                      v-model="paymentFormData.cardType"
                      placeholder="Please select card type"
                  >
                    <el-option
                        v-for="item in cardType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item prop="cardNumber" label="Card Number ">
                  <el-input v-model="paymentFormData.cardNumber" type="number"
                            placeholder="Please select Card Number"></el-input>
                </el-form-item>

                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item prop="month" label="Expiry Month">
                      <el-select
                          style="width: 100%"
                          v-model="paymentFormData.month"
                          placeholder="Please select Expiry Month"
                      >
                        <el-option
                            v-for="item in month"
                            :key="item"
                            :label="item"
                            :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="year" label="Expiry Year">
                      <el-select
                          style="width: 100%"
                          v-model="paymentFormData.year"
                          placeholder="Please select Expiry Year"
                      >
                        <el-option
                            v-for="item in year"
                            :key="item"
                            :label="item"
                            :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="cvv" label="CVV">
                      <el-input :minlength=3 :maxlength=4 type="number" placeholder="Please enter a CVV"
                                v-model="paymentFormData.cvv"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item>
                  <el-button type="primary" @click="submit('on',2)">Submit</el-button>
                  <el-button @click="quit">Cancel Shipment</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row v-if="ret == 'off'">
            <el-row>
              <div class="off-item" :class="{ 'off-item-active': offActive == 0 && isCheck }" @click="toggleItem(0)">
                <p :class="[{ 'custom-radio-active': isfromSelect },'custom-radio',]">
                  <span :class="isfromSelect ? 'el-icon-success' : ' circle'"></span>Ship From<i class="isMust"></i>
                  <span class="off-user"
                        v-if="offActive == 0 && !isCheck">{{ formData.fullCompanyName }} {{ formData.phone }} {{ formData.address1 }} {{ formData.city }} {{ formData.postalCode }}</span>
                </p>
                <i :class="offActive == 0 && isCheck?'el-icon-caret-top ':'el-icon-caret-bottom'"></i>
              </div>
              <el-col :span="16" v-if="offActive == 0 && isCheck">
                <el-form
                    style="text-align: left"
                    ref="offFormData"
                    :model="formData"
                    label-width="80px"
                    label-position="top"
                    :rules="rules"
                >
                  <el-form-item prop="country" label="Country or Territory">
                    <el-select
                        style="width: 100%"
                        v-model="formData.country"
                        placeholder="Please select country or region"
                    >
                      <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          @change="offContinueNext"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <div>
                    <el-form-item
                        prop="fullCompanyName"
                        label="Full Name or Company Name"
                    >
                      <el-input
                          v-model="formData.fullCompanyName"
                          @blur="offContinueNext"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Contact Name">
                      <el-input v-model="formData.contactName"></el-input>
                    </el-form-item>
                  </div>

                  <el-form-item prop="address1" label="Address Line 1">
                    <el-input
                        v-model="formData.address1"
                        @blur="offContinueNext"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Address Line 2">
                    <el-input v-model="formData.address2"></el-input>
                  </el-form-item>
                  <el-form-item label="Address Line 3">
                    <el-input v-model="formData.address3"></el-input>
                  </el-form-item>
                  <el-row :gutter="20">
                    <el-col :span="10">
                      <el-form-item prop="city" label="City">
                        <el-input
                            v-model="formData.city"
                            @blur="offContinueNext"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item prop="postalCode" label="Postal Code">
                        <el-input
                            v-model="formData.postalCode"
                            @blur="offContinueNext"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Other Address Information">
                        <el-input v-model="formData.information"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-checkbox v-model="formData.residentialAddress"
                  >Is this a residential address?
                  </el-checkbox
                  >
                  <el-row :gutter="20">
                    <el-col :span="10">
                      <el-form-item label="Email">
                        <el-input v-model="formData.email"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Phone">
                        <el-input v-model="formData.phone"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="Extension">
                        <el-input v-model="formData.extension"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-checkbox v-model="formData.above"
                  >Send status updates on this shipment using the email
                    provided above.
                  </el-checkbox>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-form-item label="Save As New Entry">
                        <el-switch v-model="formData.newEntry"></el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                          label="Use this as my default return address"
                      >
                        <el-switch v-model="formData.defalutAddress">
                        </el-switch>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item
                      label="Nickname"
                      v-if="formData.newEntry && !formData.defalutAddress"
                  >
                    <el-input v-model="formData.nickname"></el-input>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>

            <el-row>
              <div
                  class="off-item"
                  :class="{ 'off-item-active': offActive == 1 && isCheck }"
                  @click="toggleItem(1)"
              >
                <p
                    :class="[
                                { 'custom-radio-active': isToSelect },
                                'custom-radio',
                              ]"
                >
                  <span :class="isToSelect ? 'el-icon-success' : ' circle'"></span>Ship To<i class="isMust"></i>
                  <span class="off-user"
                        v-if="offActive == 1 && !isCheck">{{ formData1.fullCompanyName }} {{ formData1.phone }} {{ formData1.address1 }} {{ formData1.city }} {{ formData1.postalCode }}</span>

                </p>
                <i :class="offActive == 1 && isCheck?'el-icon-caret-top ':'el-icon-caret-bottom'"></i>
              </div>
              <el-col :span="16" v-if="offActive == 1 && isCheck">
                <el-form
                    style="text-align: left"
                    ref="formData1"
                    :model="formData1"
                    label-width="80px"
                    label-position="top"
                    :rules="rules"
                >
                  <el-form-item prop="country" label="Country or Territory">
                    <el-select
                        style="width: 100%"
                        v-model="formData.country"
                        placeholder="Please select country or region"
                    >
                      <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          @change="offContinueNext1"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <div>
                    <el-form-item
                        prop="fullCompanyName"
                        label="Full Name or Company Name"
                    >
                      <el-input
                          v-model="formData1.fullCompanyName"
                          @blur="offContinueNext1"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Contact Name">
                      <el-input v-model="formData1.contactName"></el-input>
                    </el-form-item>
                  </div>

                  <el-form-item prop="address1" label="Address Line 1">
                    <el-input
                        v-model="formData1.address1"
                        @blur="offContinueNext1"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Address Line 2">
                    <el-input v-model="formData1.address2"></el-input>
                  </el-form-item>
                  <el-form-item label="Address Line 3">
                    <el-input v-model="formData1.address3"></el-input>
                  </el-form-item>
                  <el-row :gutter="20">
                    <el-col :span="10">
                      <el-form-item prop="city" label="City">
                        <el-input
                            v-model="formData1.city"
                            @blur="offContinueNext1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item prop="postalCode" label="Postal Code">
                        <el-input
                            v-model="formData1.postalCode"
                            @blur="offContinueNext1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Other Address Information">
                        <el-input v-model="formData1.information"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-checkbox v-model="formData1.residentialAddress"
                  >Is this a residential address?
                  </el-checkbox
                  >
                  <el-row :gutter="20">
                    <el-col :span="10">
                      <el-form-item label="Email">
                        <el-input v-model="formData1.email"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Phone">
                        <el-input v-model="formData1.phone"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="Extension">
                        <el-input v-model="formData1.extension"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p>
                    UPS may use the email address and/or mobile number provided
                    to update your receiver about the status of their parcel.
                  </p>

                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-form-item label="Save As New Entry">
                        <el-switch v-model="formData1.newEntry"></el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Nickname" v-if="formData1.newEntry">
                        <el-input v-model="formData1.nickname"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-col>
            </el-row>

            <el-row>
              <div
                  class="off-item"
                  :class="{ 'off-item-active': offActive == 2 && isCheck }"
                  @click="toggleItem(2)"
              >
                <p :class="[
                                { 'custom-radio-active': isPayment },
                                'custom-radio',
                              ]">
                              <span
                                  :class="isPayment ? 'el-icon-success' : ' circle'"
                              ></span

                              >Payment<i class="isMust"></i>
                </p>
                <i :class="offActive == 2 && isCheck?'el-icon-caret-top ':'el-icon-caret-bottom'"></i>
              </div>
              <el-col :span="16" v-if="offActive == 2 && isCheck">
                <p class="total-charges"><span>Total Charges:</span> <span>65USD</span></p>
                <el-form
                    style="text-align: left"
                    ref="paymentFormData"
                    :model="paymentFormData"
                    label-width="80px"
                    label-position="top"
                    :rules="paymentRuls"
                >
                  <el-form-item prop="cardType" label="Card Type ">
                    <el-select
                        style="width: 100%"
                        v-model="paymentFormData.cardType"
                        placeholder="Please select card type"
                    >
                      <el-option
                          v-for="item in cardType"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item prop="cardNumber" label="Card Number ">
                    <el-input v-model="paymentFormData.cardNumber" type="number"
                              placeholder="Please select Card Number"></el-input>
                  </el-form-item>

                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item prop="month" label="Expiry Month">
                        <el-select
                            style="width: 100%"
                            v-model="paymentFormData.month"
                            placeholder="Please select Expiry Month"
                        >
                          <el-option
                              v-for="item in month"
                              :key="item"
                              :label="item"
                              :value="item"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item prop="year" label="Expiry Year">
                        <el-select
                            style="width: 100%"
                            v-model="paymentFormData.year"
                            placeholder="Please select Expiry Year"
                        >
                          <el-option
                              v-for="(item,index) in year"
                              :key="index"
                              :label="item"
                              :value="index"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item prop="cvv" label="CVV">
                        <el-input :minlength=3 :maxlength=4 type="number" placeholder="Please enter a CVV"
                                  v-model="paymentFormData.cvv"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>


                  <el-form-item>
                    <el-button type="primary" @click="submit('off',2)">Submit</el-button>
                    <el-button @click="quit">Cancel Shipment</el-button>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </el-row>
        </el-main>

      </el-container>
    </div>
  </el-row>
</template>

<script>
import {websitePage} from "../api/index.js";

export default {
  data() {
    return {
      pageId: this.$route.query.pageId,
      pageContent: {},
      formData: {
        country: 0,
        fullCompanyName: "",
        contactName: "",
        address1: "",
        address2: "",
        address3: "",
        city: "",
        postalCode: "",
        information: "",
        residentialAddress: false,
        email: "",
        phone: "",
        extension: "",
        above: false,
        newEntry: false,
        defalutAddress: false,
        nickname: "",
      },
      formData1: {
        country: 0,
        fullCompanyName: "",
        contactName: "",
        address1: "",
        address2: "",
        address3: "",
        city: "",
        postalCode: "",
        information: "",
        residentialAddress: false,
        email: "",
        phone: "",
        extension: "",
        above: false,
        newEntry: false,
        defalutAddress: false,
        nickname: "",
      },
      rules: {
        country: [
          {required: true, message: "country is required", trigger: "change"},
        ],
        fullCompanyName: [{
          required: true,
          message: "Full Name or Company Name is required.",
          trigger: "blur",
        },],
        address1: [{
          required: true,
          message: "Address Line 1 is required.",
          trigger: "blur",
        },],
        city: [
          {required: true, message: "City is required.", trigger: "blur"},
        ],
        postalCode: [{
          required: true,
          message: "Postal Code is required.",
          trigger: "blur",
        },],
      },
      options: [
        {label: "Albania", value: 0},
        {label: "Algeria", value: 1},
        {label: "Angola", value: 2},
        {label: "Argentina", value: 3},
        {label: "Australia", value: 4},
        {label: "Austria", value: 5},
        {label: "Azerbaijan", value: 6},
        {label: "Bahamas", value: 7},
        {label: "Bahrain", value: 8},
        {label: "Bangiadesh", value: 9},
        {label: "Belgium", value: 10},
        {label: "Bermuda", value: 11},
        {label: "Bolivia", value: 12},
        {label: "Bosnia", value: 13},
        {label: "Brazil", valuw: 14},
        {label: "Bulgaria", value: 15},
        {label: "Burundi", value: 16},
        {label: "Cameroon", value: 17},
        {label: "Canada", value: 18},
        {label: "Cayman Islands", value: 19},
        {label: "Chile", value: 20},
        {label: "China Mainland", value: 21},
        {label: "Colombia", value: 22},
        {label: "Costa Rica", value: 23},
        {label: "Croatia", value: 24},
        {label: "Cyprus", value: 25},
        {label: "Czech Republic", value: 26},
        {label: "Democriatic Republic of Congo", value: 27},
        {label: "Denmark", value: 28},
        {label: "Djibouti", value: 29},
        {label: "Dominican Republic", value: 30},
        {label: "Ecauador", value: 31},
        {label: "Egypt", value: 32},
        {label: "EL Salvador", value: 33},
        {label: "Estonia", value: 34},
        {label: "Ethiopia", value: 35},
        {label: "Finland", value: 36},
        {label: "France", value: 37},
        {label: "Germany", value: 38},
        {label: "Ghana", value: 39},
        {label: "Gibraltar", value: 40},
        {label: "Greece", value: 41},
      ],
      active: 0,
      ret: "on",
      offActive: -1,
      isCheck: false,
      isfromSelect: false,
      isToSelect: false,
      isPayment: false,
      paymentFormData: {
        cardType: 0,
        cardNumber: null,
        month: null,
        year: null,
        cvv: null,
      },
      cardType: [{label: "VISA", value: 0}],
      paymentRuls: {
        cardType: [{
          required: true,
          message: "card type is required",
          trigger: "change",
        },],
        cardNumber: [{
          required: true,
          message: "card number is required",
          trigger: "blur",
        },],
        month: [{
          required: true,
          message: "Expiry Month is required. is required",
          trigger: "change",
        },],
        year: [{
          required: true,
          message: "Expiry year is required. is required",
          trigger: "change",
        },],
        cvv: [
          {required: true, message: "CVV is required.", trigger: "blur"},
        ],
      },
      month: [],
      year: [],
    };
  },
  mounted() {
    for (let i = 1; i < 13; i++) {
      if(i<10){
        this.month.push('0'+i);
      }else{
        this.month.push(''+ i);
      }
    }

    const date = new Date();
    for (let i = date.getFullYear(); i < 2100; i++) {
      this.year.push(i);
    }
  },
  methods: {

    onSubmit() {
      console.log("submit!");
    },
    continueNext(active) {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.active = active;
          this.isfromSelect = true;
        } else {
          return false;
        }
      });
    },
    offContinueNext() {
      let ret = 0;
      if (!this.formData.fullCompanyName) {
        ret = 1;
      }
      if (!this.formData.address1) {
        ret = 1;
      }
      if (!this.formData.city) {
        ret = 1;
      }
      if (!this.formData.postalCode) {
        ret = 1;
      }
      console.log(ret);
      if (ret == 0) {
        this.isfromSelect = true;
        this.isCheck = true;
      } else {
        this.isfromSelect = false;
      }
      console.log(this.isfromSelect);
    },
    offContinueNext1() {
      this.$refs.formData1.validate((valid) => {
        if (valid) {
          this.isToSelect = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    continueNext1(active) {
      this.$refs.formData1.validate((valid) => {
        if (valid) {
          this.active = active;
          this.isToSelect = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    toggle(ret) {
      this.ret = ret;
    },
    toggleItem(active) {
      this.offActive = active;
      this.isCheck = !this.isCheck;
    },
    //支付
    submit(data, active) {
      if (data == 'on') {
        this.active = active;
      } else {
        this.offActive = active;
      }
      this.$refs.paymentFormData.validate((valid) => {
        if (valid) {
          this.isPayment = true;
          this.$message.error("Not Supported CARD");
        } else {
          this.$message.error("* is required");
          return false;
        }
      });
    },
    quit() {
      this.$confirm('Select Yes to cancel and discard any information you have entered /n Select No to continue with this shipment', 'Are you sure you want to cancel?', {
        confirmButtonText: 'yes',
        cancelButtonText: 'no',
      }).then(() => {
        this.active = 0
        this.cancel()
      }).catch(() => {

      });

    },
    preItem() {
      this.active = 0
    },
    cancel() {
      this.formData = {
        country: 0,
        fullCompanyName: "",
        contactName: "",
        address1: "",
        address2: "",
        address3: "",
        city: "",
        postalCode: "",
        information: "",
        residentialAddress: false,
        email: "",
        phone: "",
        extension: "",
        above: false,
        newEntry: false,
        defalutAddress: false,
        nickname: "",
      }
      this.formData1 = this.formData
      this.paymentFormData = {
        cardType: 0,
        cardNumber: null,
        month: null,
        year: null,
        cvv: null,
      }
      this.isfromSelect = false;
      this.isToSelect = false;
      this.isPayment = false;
    }
  },
  computed: {},
  watch: {
    pageId(val) {
      if (val) {
        console.log(val);
        this.details();
      }
    },
  },
};
</script>


<style lang="less" scoped>
//  @import "../assets/less/common.less";
.aside-menu-list {
  padding: 15px 0;
  margin: 0 0 20px;
  border: 1px solid #c0d1dc;

  li {
    position: relative;
  }

  .aside-menu-title,
  a {
    padding: 5px 5px 5px 25px;
    display: block;
    position: relative;
    font-size: 16px;
    text-align: left;
    font-weight: 600;
  }

  .is-active::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #c31127;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    a {
      color: #3982ea;

      &:hover {
        color: #2f5179;
      }
    }

    .menu-icon {
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 1px solid red;
      margin-right: 10px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .aside-menu-li-img {
    padding-bottom: 40px;
  }
}

.aside-Guide {
  padding: 15px;

  .aside-menu-title {
    padding-left: 10px;
  }

  li {
    p {
      font-size: 16px;
      text-align: left;
      font-weight: 500;
      color: #333;
    }
  }

  .font-18 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 15px;
  }
}

.page-main-title {
  text-align: left;
  font-size: 30px;
  font-weight: 400;
  color: #24549d;
  font-size: 30px;
  margin-top: 30px;
}

.order-el-main {
  .h1-title {
    font-size: 24px;
    text-align: left;
    padding-bottom: 20px;
  }

  .order-flex-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .order-step {
      background: #eaeaea;
      width: 80%;
      padding: 10px 0 10px 10px;
      border-radius: 10px;
    }

    .title {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .toggle {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    text-align: right;
    margin-bottom: 20px;

    span {
      border: 1px solid #666;
      padding: 5px 10px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
      cursor: pointer;

      &:nth-child(1) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        margin-left: 10px;
      }

      &:nth-child(2) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .toggle-active {
      background: #00857d;
      color: #fff;
    }
  }
}

/deep/ .el-form-item {
  .el-form-item__label {
    font-size: 16px;
    color: #000;
    padding-bottom: 0;
  }

  .el-input__inner {
    border: 1px solid #666666;
    font-size: 16px;
  }

  .el-form-item__error {
    font-size: 16px;
    font-weight: 600;
  }
}

/deep/ .el-checkbox {
  margin-top: 10px;
  margin-bottom: 10px;

  .el-checkbox__label {
    font-size: 16px;
    color: #000;
  }

  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border: 1px solid #666666;
  }

  .el-checkbox__inner::after {
    width: 8px;
    height: 10px;
  }
}

.isMust {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url("../assets/img/isMust.png") no-repeat;
  background-size: cover;
}

.off-item {
  text-align: left;
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  padding: 10px 15px;
  background: #f2f2f2;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .el-icon-caret-bottom, .el-icon-caret-top {
    font-size: 24px;
  }
}

.off-item-active {
  border: 1px solid #757575;
  border-radius: 5px;
}

.custom-radio {
  font-size: 18px;
  font-weight: 500;
  color: #666;
  display: flex;
  flex-direction: row;
  align-items: center;

  .circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #666666;
    border-radius: 50%;
    margin-right: 5px;
  }

  .el-icon-success {
    font-size: 25px;
    color: #3982ea;
  }

  .off-user {
    font-size: 14px;
    padding-left: 20px;
  }
}

.custom-radio-active {
  color: #3982ea;
}

.total-charges {
  font-weight: 500;
  padding-top: 10px;
  text-align: left;

  span {
    font-size: 16px;
    color: #000
  }
}

.shipFormEdit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .el-icon-edit, span {
    color: #409EFF;
    cursor: pointer;
  }

}

/deep/ .el-divider--horizontal {
  margin: 10px 0;
}

.info {
  text-align: left;
  color: #333;
  margin-bottom: 20px;
  font-weight: 500;
}
</style>